import React from 'react';
import styled from 'styled-components';
import { Table, TableHeader, TableRow, TableCell, TableBody } from 'grommet';

import { Helmet } from 'react-helmet';
import Layout from '../components/Layout/Layout';
import AppContext from '../components/AppContext';
import EmergencyService from '../components/Layout/EmergencyService';
import Map from '../components/Map/Map';
import RevertPadding from '../components/RevertPadding';

export const frontmatter = {
  title: 'Notdienst',
  menu: 7,
};

const pharmacies = [
  {
    name: 'Hirsch-Apotheke',
    phone: '05631 / 25 45',
    street: 'Prof.-Kümmell-Str. 2',
    city: '34497 Korbach',
  },
  {
    name: 'Adler Apotheke',
    phone: '05634 / 309',
    street: 'Wildunger Str. 9',
    city: '34513  Waldeck-Sachsenhausen',
  },
  {
    name: ' Ahorn Apotheke',
    phone: ' 05631 / 40 40',
    street: 'Bahnhofstraße 7',
    city: ' 34497 Korbach',
  },
  {
    name: ' Apotheke Adorf',
    phone: ' 05633 / 912 30',
    street: 'Hauptstraße 2',
    city: ' 34519 Diemelsee-Adorf',
  },
  {
    name: ' Apotheke am Berndorfer Tor',
    phone: ' 05631 / 20 88',
    street: 'Am Berndorfer Tor 5',
    city: ' 34497 Korbach',
  },
  {
    name: ' Bären Apotheke',
    phone: ' 05631 / 73 70',
    street: 'Solinger Str. 28',
    city: ' 34497 Korbach',
  },
  {
    name: ' Edersee-Apotheke Vöhl',
    phone: ' 05635 / 214',
    street: 'Schloßstraße 2',
    city: ' 34516 Vöhl',
  },
  {
    name: ' Eisenberg Apotheke',
    phone: ' 05631 / 40 21',
    street: 'Flechtdorfer Str. 3a',
    city: ' 34497 Korbach',
  },
  {
    name: ' Kilian Apotheke',
    phone: ' 05631 / 24 46',
    street: 'Arolser Landstraße 16 A',
    city: '34497 Korbach',
  },
  {
    name: ' Stern Apotheke',
    phone: ' 05631 / 20 89',
    street: 'Bahnhofstraße 12',
    city: '34497 Korbach',
  },
];

export default () => (
  <Layout>
    <Helmet>
      <title>Notdienst</title>
    </Helmet>
    <h1>Apothekennotdienst</h1>
    <p>
      Rund um die Uhr gewährleisten die Korbacher Apotheken die Versorgung mit
      Medikamenten und Pharmazeutika. In dringenden Fällen können Sie also auch
      außerhalb der regulären Öffnungszeiten immer eine Notdienst-Apotheke
      auffinden.
    </p>
    <p>
      Auf dieser Seite finden Sie immer den aktuellen Notdienst. Tipp: Speichern
      Sie sich diese Seite als Lesezeichen um notfalls die richtige Apotheke
      immer schnell bereit zu haben.
    </p>
    {/* <p>
      Außerdem können Sie hier unseren Notdienstkalender für das ganze Jahr
      herunterladen. Die gedruckte Version im praktischen Taschenformat gibt es
      bei uns vor Ort zum Mitnehmen.
    </p> */}
    <h2 id="aktuell">Aktueller Apotheken-Notdienst in Korbach und Umgebung</h2>
    <AppContext.Consumer>
      {(context) => {
        if (!context || !context.emergencyServices) {
          return <p>Lädt...</p>;
        }
        const markers = context.emergencyServices.current.map((es) => ({
          lat: parseFloat(es.pharmacy.lat),
          long: parseFloat(es.pharmacy.lon),
          popup: (
            <>
              <h6>{es.pharmacy.name}</h6>
              <div>
                {es.pharmacy.street}
                <br />
                {es.pharmacy.zipCode} {es.pharmacy.location}
              </div>
              <p>Tel. {es.pharmacy.phone}</p>
            </>
          ),
          tooltip: es.pharmacy.name,
        }));
        return (
          <>
            {context.emergencyServices.current.map((es) => (
              <EmergencyService service={es} key={es.pharmacy.name} />
            ))}
            <RevertPadding>
              <Map
                position={[51.2732954, 8.8713331]}
                markers={markers}
                zoom={17}
              />
            </RevertPadding>
            <h3>Kommender Apotheken-Notdienst</h3>
            {context.emergencyServices.upcoming.map((es) => (
              <EmergencyService service={es} key={es.pharmacy.name} upcoming />
            ))}
          </>
        );
      }}
    </AppContext.Consumer>
    <h2>Der Korbacher Notdienstkreis</h2>
    <p>
      Die Hirsch-Apotheke gehört dem Notdienstkreis Nr. 079 der
      Landesapothekerkammer Hessen an. Neben sieben Korbacher Apotheken sind
      dort auch je eine Apotheke aus Adorf, Sachsenhausen und Vöhl vertreten.
      Diese zehn Notdienstapotheken gewährleisten gemeinsam eine durchgehende
      pharmazeutische Notversorgung.
    </p>
    <p>
      {' '}
      Die Apotheke Adorf und die Adler Apotheke in Sachsenhausen haben immer
      gemeinsamen Notdienst. Somit steht jede Apotheke alle neun Tage für 24
      Stunden im Notdienst bereit.
    </p>
    <TableContainer>
      <Table>
        <TableHeader>
          <TableRow>
            <TableCell scope="col">
              <strong>Apotheke</strong>
            </TableCell>
            <TableCell scope="col">
              <strong>Telefon</strong>
            </TableCell>
            <TableCell scope="col">
              <strong>Anschrift</strong>
            </TableCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {pharmacies.map((pharmacy) => (
            <TableRow key={pharmacy.name}>
              <TableCell scope="row">
                <strong>{pharmacy.name}</strong>
              </TableCell>
              <TableCell>{pharmacy.phone}</TableCell>
              <TableCell>
                {pharmacy.street}
                <br />
                {pharmacy.city}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </Layout>
);

const TableContainer = styled.div`
  margin: 10px auto;
  text-align: left;
`;
